// Here you can add other styles
.input-group {
  &-label {
    font-size: 1rem;
    margin: 0.2rem 0;
    font-family: sans-serif;
  }
}

.form-control {
  transition: outline 0.1s ease;
  &:focus-visible {
    outline: none;
  }
  &:focus {
    box-shadow: none;
    transition: outline 0.15s ease;
    border: #bbd4ff 1px solid;
    outline: 3.5px solid rgba(141, 139, 255, 0.796);
    &.border-danger {
      border: #ff6565 1px solid;
      outline: 3.5px solid rgba(255, 139, 139, 0.796);
    }
  }
  &::placeholder {
    color: #b4b4b4;
  }
}
.form-select {
  &:focus {
    box-shadow: none;
    transition: outline 0.15s ease;
    border: #bbd4ff 1px solid;
    outline: 3.5px solid rgba(141, 139, 255, 0.796);
    &.border-danger {
      border: #ff6565 1px solid;
      outline: 3.5px solid rgba(255, 139, 139, 0.796);
    }
  }
}

.button-group {
  display: flex;
  width: auto;
}

.btn-icon {
  width: 40px;
  height: 40px;
}

.btn {
  padding: 0.375rem 0.75rem;
  text-align: center;
  border: 1px solid transparent;
  font-family: sans-serif;
  border-radius: 0.25rem;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:disabled {
    opacity: 0.8;
  }
  &-success {
    border-color: $primaryColor !important;
    background-color: $primaryColor !important;
    color: #fff;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba($primaryColor, 0.5) !important;
    }
  }
  &-dark {
    border-color: $thirdColor !important;
    background-color: $thirdColor !important;
    color: #fff;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba($thirdColor, 0.5) !important;
    }
  }
  &-info {
    border-color: $fourColor !important;
    background-color: $fourColor !important;
    color: #fff;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba($fourColor, 0.5) !important;
    }
  }
}

.br {
  &-l {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    @media screen and (min-width: 576px) {
      &-md {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
  &-r {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    @media screen and (min-width: 576px) {
      &-md {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}

.export-button {
  width: 90px;
}

.text-primary {
  color: $primaryColor !important;
}

.text-secondary {
  color: $secondaryColor !important;
}
.text-third {
  color: $thirdColor !important;
}

.text-four {
  color: $fourColor !important;
}
.text-five {
  color: $fiveColor !important;
}

.text-danger {
  color: rgb(229, 83, 83);
}

.border-danger {
  border-color: rgb(229, 83, 83);
}

.alert {
  padding: 0.25rem;
  text-align: center;
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 15px;
  border-radius: 0.25rem;
  &-danger {
    border: 0.5px solid #f7cbcb;
    background-color: #fadddd;
    color: #893232;
  }
}

.table {
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

  min-width: 450px;
  border-collapse: collapse;
  caption-side: bottom;
  border: 0 solid;
  border-color: inherit;

  & th {
    background-color: #2c2c2c;
    color: #fff;
    padding: 0.5rem;
  }
  & td {
    border: 0;
    border-style: double;
    border-color: #000000;
    border-bottom-width: 1px;
    padding: 10px 5px;
    background-color: white;
    color: rgba(44, 56, 74, 0.95);
    padding: 0.5rem;
  }
}

.td-none {
  text-decoration: none;
}

.border-none {
  border: none;
}

#root ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}
#root ::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}
#root ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #666666;
}

.signwall-container {
  width: 100%;
  display: flex;
  box-shadow: 2px -1px 29px -10px #050504;
  border-radius: 0.25rem;
  background-color: #ffffff;
  flex-direction: column;
  margin: auto;
  & .banner {
    background-color: #080045;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    display: flex;
    width: 100%;
    min-height: 80px;
    min-width: 100px;
    &-image {
      width: 100%;
      padding: 15px;
      margin: auto;
      display: flex;
      max-width: 330px;
    }
  }
  @media screen and (min-width: 360px) {
    max-width: 330px;
  }
  @media screen and (min-width: 540px) {
    max-width: 400px;
  }
}
